import React, { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { AiOutlineReload } from 'react-icons/ai';
import { format, parseISO } from 'date-fns';
import content_placeholder from '../../../assets/images/content_placeholder.png';
import { MdPeopleOutline, MdOutlineTextsms, MdOutlineMail } from 'react-icons/md';

const ContentTab = () => {
  const [content, setContent] = useState([]);

  // Load images dynamically and set the content when the component mounts
  useEffect(() => {
    const loadImage = async () => {
      const imagesData = await Promise.all([
        import('../../../assets/images/contents/1.jpeg'),
        import('../../../assets/images/contents/2.jpeg'),
        import('../../../assets/images/contents/3.jpeg'),
      ]);

      setContent([
        {
          image_name: imagesData[0].default,
          title: "1BHK Room",
          description: "Book your dream 1BHK room today!",
          created_at: "2022-12-12",
        },
        {
          image_name: imagesData[1].default,
          title: "2BHK Room",
          description: "Experience luxury living with our spacious 2BHK rooms.",
          created_at: "2022-12-12",
        },
        {
          image_name: imagesData[2].default,
          title: "3BHK Room",
          description: "Discover the perfect 3BHK room for your family.",
          created_at: "2022-12-12",
        },
      ]);
    };

    loadImage();
  }, []);

  // Phone number for WhatsApp link
  const phoneNumber = '8483807725';

  // Generate WhatsApp link with pre-filled message, phone number, and image
  const generateWhatsAppLink = (imageData) => {
    const message = `Check out this image: ${imageData.title}`;
    const imageBase64 = imageData.image_name.replace(/^data:image\/[a-z]+;base64,/, '');
    return `whatsapp://send?text=${encodeURIComponent(message)}%0a${encodeURIComponent(imageBase64)}&phone=${encodeURIComponent(phoneNumber)}`;
  };

  return (
    <div className='p-4 grid gap-4 md:grid'>
      <div className='grid grid-cols-[auto_50px] gap-2'>
        <div className='flex items-center justify-center relative'>
          <BsSearch className='absolute left-4 text-blue-800 font-normal' />
          <input
            className="border-2 w-full rounded-lg h-10 px-4 py-3 pl-9 outline-none"
            type="text"
            placeholder="Search"
          />
        </div>
        <div className='justify-self-center'>
          <div className='bg-white rounded-full h-10 w-10 flex items-center justify-center'>
            <AiOutlineReload className='text-2xl text-blue-600' />
          </div>
        </div>
      </div>
      {content.map((item, index) => {
        return (
          <div className='bg-white p-3 rounded-md grid gap-4' key={index}>
            <img className='w-full' src={item.image_name ? item.image_name : content_placeholder} alt="" />
            <div className='text-start grid gap-2' key={index}>
              <div className='font-semibold text-lg leading-5'>{item?.title}</div>
              <div className='font-normal text-sm'>
                <span className='leading-5'>{item?.description}</span>
              </div>
              <div className='font-normal text-sm leading-5'>
                <span className='font-semibold'>Created on {' '}</span>
                {item?.created_at ? format(parseISO(item?.created_at), 'dd MMM yyyy') : null}
              </div>
            </div>
            <div className='flex justify-between mt-2 px-4'>
              <div className='rounded-full h-12 w-12 flex items-center justify-center bg-[#e6f2ff]'>
                <MdOutlineTextsms className='text-2xl text-blue-600' />
              </div>
              <div className='rounded-full h-12 w-12 flex items-center justify-center bg-[#e6f2ff]'>
                <MdPeopleOutline className='text-2xl text-blue-600' />
              </div>
              <div className='rounded-full h-12 w-12 flex items-center justify-center bg-[#e6f2ff]'>
                <MdOutlineMail className='text-2xl text-blue-600' />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContentTab;
