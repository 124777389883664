import { React, useState, useEffect } from 'react';
import Logo from '../../assets/images/hello.jpg';
import { BsArrowRight } from 'react-icons/bs';
import {  useNavigate, useLocation } from 'react-router-dom';
import { loginUrl, checkIsLoggedInUrl } from '../../config/apiEndpoints';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { postRequest } from '../../store/common';
const Login = () => {
   const [username, setUserName] = useState('');
   const [password, setPassword] = useState('');
   const [usernameError, setUserNameError] = useState('');
   const [passwordError, setPasswordError] = useState('');
   const [formError, setFormError] = useState('');
   const navigate = useNavigate();
   const location = useLocation();
   const handlePassword = (e) => {
      setPassword(e.target.value);
      setPasswordError('');
      setFormError('');
   }
   const handleUsername = (e) => {
      setUserName(e.target.value);
      setUserNameError('');
      setFormError('');
   }
   const handleSubmit = async (e) => {
      e.preventDefault();
      let hasError = false;
      if (!username) {
         setUserNameError('Username is required');
         hasError = true;
      } 
      if (!password) {
         setPasswordError('Password is required');
         hasError = true;
      }
      if (hasError) {
         toast.error('Please fill the required fields');
         return;
      }
	   // let deviceToken = localStorage.getItem('fcmToken');
      const deviceToken = new URLSearchParams(location.search).get('device_token');

      let body =  {
         email: username,
         password: password,
         device_token: deviceToken 
      }
      const data = await postRequest(loginUrl, { body, showSuccess: 0 });
      const token = data.token;
      if (!isEmpty(token)) {
         localStorage.setItem('token', token);
         localStorage.setItem('userName', data.user_name);
         navigate('/leads');
      }
   }

   useEffect(() => {
      const checkAlreadyLogin = async () => {
         const deviceToken = new URLSearchParams(location.search).get('device_token');
         let body =  {
            device_token: deviceToken 
         }
         await postRequest(checkIsLoggedInUrl, { body, showSuccess: 0, clearBackHistory: true, showError:0 , redirectOnSuccessUrl:"/leads"});
      }
      checkAlreadyLogin();
  }, []);

   return (
      <div className='grid py-8 px-4 gap-20 h-screen'>
         <div className='grid gap-6 mt-10'>
            <div className='grid content-center justify-center'>
               <div className='text-xl font-semibold'>Welcome To</div>
               <div className='text-3xl font-bold text-blue-500 pt-1'>Hello Homes</div>
            </div>
            <form className='grid gap-3.5 lg:mx-96' onSubmit={handleSubmit}>
               {formError && (
                  <p>{formError}</p>
               )} 
               <div className='grid gap-3 content-center'>
                  <label className='text-md text-gray-400 font-lg'>Username</label>
                  <input type="text" className={`focus-within:outline-none border text-md font-normal text-black rounded-lg py-2 px-4 ${usernameError ? 'border-red-500' : 'border-gray-500'}`} onChange={handleUsername}/>
                  { usernameError && (
                     <p className='text-xs font-medium text-red-500'>{usernameError}</p>
                  )}
               </div>
               <div className='grid gap-3 content-center'>
                  <label className='text-md text-gray-400 font-lg'>Password</label>
                  <input type="password" className={`focus-within:outline-none border border-gray-500 text-md font-normal text-black rounded-lg py-2 px-4 ${passwordError ? 'border-red-500' : 'border-gray-500'}`} value={password} onChange={handlePassword} />
                  {passwordError && (
                     <p className='text-xs font-medium text-red-500'>{passwordError}</p>
                  )}
               </div>
               <div className='grid justify-center mt-4'>
                  <button className='bg-blue-600 text-md font-semibold text-white rounded-lg py-2 px-4 flex justify-between items-center gap-2 h-10' type='submit'> 
                        <span className='text-base font-normal'>Login</span>
                        <BsArrowRight className='mt-0.5' />
                     </button>
               </div>
            </form>
         </div>
         <div className='grid justify-center gap-3'>
            <span className='justify-self-center text-md font-medium font-md text-gray-400 self-end'>Powered by</span>
            <img src={Logo} alt="" className='h-20 w-30' />
         </div>
      </div>
  )
}

export default Login
