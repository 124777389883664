import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { FaWhatsapp } from 'react-icons/fa';
import { IoMdChatboxes } from 'react-icons/io';

import { IoMdCall, IoIosArrowDown } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaPhone } from 'react-icons/fa';
import { getRequest, postRequest } from '../../store/common';
import { leadUrl, callHistoryUrl } from '../../config/apiEndpoints';
import { FiPlus } from 'react-icons/fi';
import FilterSidebar from './FilterSidebar';
import { toast } from 'react-toastify';
import NextLeads from '../Leads/NextLeads'
import { nextCallStore } from '../../store/nextCall';
import emptyImage from '../../assets/images/empty.avif';
import { FaCalendarAlt } from 'react-icons/fa';

const Lead = () => {
    console.log("leads loading")
    const webViewRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [leadsData, setLeadsData] = useState([]);
    const [userCallNumber, setUserCallNumber] = useState(null);
    const [nextPageUrl, setNextPageUrl] = useState(leadUrl.byTelecaller);
    const [totalAllocations, setTotalAllocations] = useState(0);
    const [isNextModalOpen, setIsNextModalOpen] = useState(false);
    const navigate = useNavigate();
    const [userOnCall, setUserOnCall] = useState(null);
    const [nextLeadsData, setNextLeadsData] = useState([]);
    
    const {selectedDoctorIndex, setSelectedDoctorIndex, showNextCallPopup, setShowNextCallPopup, setSelectedDoctorId} = nextCallStore()
    const handleLogout = () => {
        localStorage.removeItem("token");
    };
    const callRef = useRef(null);

    const fetchFirstTimeLeadsData = async () => {
        let url = leadUrl.byTelecaller;
        let body = localStorage.getItem('filter');
        if (body) {
            body = JSON.parse(body);
        } else {
            body = {}; // Set default value if 'filter' is not available in localStorage
        }
        const response = await getRequest(url, { body });
        setLeadsData(response.data);
        setNextPageUrl(response.next_page_url || ''); // Set empty string if there is no next page URL
        setTotalAllocations(response.total);
    };

    useEffect(() => {
        fetchFirstTimeLeadsData();
    }, []);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const fetchLeadsData = async () => {
        if (!nextPageUrl) {
            return;
        }
        let url = nextPageUrl;
        let body = localStorage.getItem('filter');
        if (body) {
            body = JSON.parse(body);
        } else {
            body = {};
        }
        const response = await getRequest(url, { body });
        setLeadsData((prevLeadsData) => [...prevLeadsData, ...response.data]);
        setNextPageUrl(response.next_page_url || '');
    };



    const handleFilterChange = async () => {
        toggleSidebar();
        fetchFirstTimeLeadsData();
        setSelectedDoctorIndex()
        setSelectedDoctorId()
    };

    const handleLoadMore = () => {
        fetchLeadsData();
    };

    const redirectToLead = (id, doctorIndex) => {
        if (!isEmpty(userOnCall) && userOnCall.client_id !== id) {
            toast.error('Please dispose your first call.');
            return;
        }
        setSelectedDoctorIndex(doctorIndex)
        setSelectedDoctorId(id)
        navigate(`/lead/${id}`);
    };

    const getLeadTypeColor = (leadType) => {
        switch (leadType) {
            case 'Hot':
                return '#FF0000'; // Red color for hot leads
            case 'Cold':
                return '#0000FF'; // Blue color for cold leads
            case 'Warm':
                return '#FFA500'; // Orange color for warm leads
            case 'Drop':
                return '#808080'; // Gray color for drop leads
            default:
                return '#FFFFFF'; // Default color for unknown lead types
        }
    };

    useEffect(()=>{
        const fetchNextLeadsData = async () => {
            let url = leadUrl.nextLeads;
            let body = localStorage.getItem('filter');
            if (body) {
                body = JSON.parse(body);
            } else {
                body = {};
            }
            body.selected_doctor = selectedDoctorIndex;
            body.next_calls_data = true;
            const response = await getRequest(url, { body });
            if(isEmpty(response.data)) {
                setShowNextCallPopup(false)
            }
            else{
                setNextLeadsData(response.data);
            }
        };
        if(showNextCallPopup)
        {
            fetchNextLeadsData()
        }
    }, [showNextCallPopup, setShowNextCallPopup, selectedDoctorIndex])

    useEffect(() => {
        if(showNextCallPopup && !isEmpty(nextLeadsData))
        {
            setIsNextModalOpen(true)
        }
    }, [showNextCallPopup, nextLeadsData])

        useEffect(() => {
        const checkUserOnCall = async () => {
            let url = callHistoryUrl.checkUserOnCall;
            let user = await getRequest(url);
            setUserOnCall(user);
        };
        checkUserOnCall();
    }, []);

    useEffect(() => {
        if (userCallNumber && !isEmpty(userOnCall) && callRef.current) {
            callRef.current.click();
            navigate(`/lead/${userOnCall.client_id}`);
        }
    }, [userCallNumber, userOnCall, navigate]);

    const startCall = async (id, mobileNo, doctorIndex) => {
        if (!isEmpty(userOnCall)) {
            toast.error('Please dispose your first call.');
            return;
        }
        let url = `${callHistoryUrl.callHistory}`;
        let result = await postRequest(url, { body: { client_id: id }, showSuccess: 0 });
        console.log('startCall', result);
        if (!isEmpty(result)) {
            setSelectedDoctorIndex(doctorIndex)
            setSelectedDoctorId(id)
            setUserOnCall(result);
            try {
                const jsCode = `AndroidBridge.onMobileNumberSelected('${mobileNo}');`;
                eval(jsCode)
                // webViewRef.current.injectJavaScript(jsCode);
                navigate(`/lead/${result.client_id}`);
            } catch (error) {
                console.log(error)
                setUserCallNumber(mobileNo);                
            }
        }
    };

    const addRemark = async (id, doctorIndex) => {
        if (!isEmpty(userOnCall)) {
            toast.error('Please dispose your first call.');
            return;
        }
        setSelectedDoctorIndex(doctorIndex)
        setSelectedDoctorId(id)
        navigate(`/remark/${id}`);
    };
    const hideNextModal = async () => {
        setIsNextModalOpen(false)
    };
    const headerHeight = 250;
    return (
        <div>
            {isNextModalOpen && isEmpty(userOnCall) && (
                <NextLeads leadsData={nextLeadsData} hideNextModal={hideNextModal}/>
            )}
            <div
                style={{ background: 'linear-gradient(45deg, #3167AF 0%, #00BCD4 100%)' }}
                className='py-5 px-4 grid grid-rows-1 gap-5'  ref={webViewRef}
            >
                <div className='flex text-white'>
                    <div className='flex'>
                        <div
                            className='text-2xl leading-8 text-left font-normal mr-auto cursor-pointer'
                            onClick={toggleSidebar}
                        >
                            &#9776;
                        </div>
                        <span className='ml-3 text-2xl leading-8 text-left font-normal'>{localStorage.getItem('userName')}</span>
                    </div>
                    <div className='flex items-center justify-end ml-auto'>
                        <div
                            className='text-2xl font-normal three-dots cursor-pointer'
                            onClick={() => setShowDropdown(!showDropdown)}
                        >
                            {showDropdown && (
                                <div className='fixed top-0 bottom-0 left-0 right-0 cursor-default'>
                                    <div className='grid gap-3 py-4 absolute top-12 right-6 w-28 bg-white shadow-md rounded-md'>
                                        <a
                                            className='text-black text-sm font-semibold cursor-pointer justify-self-center'
                                            onClick={handleLogout}
                                            href='/login'
                                        >
                                            Logout
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='block text-end text-white font-medium'>Total Allocation: {totalAllocations}</div>
                </div>
            </div>
            <FilterSidebar
                showSidebar={showSidebar}
                onToggleSidebar={toggleSidebar}
                onHandleFilterChange={handleFilterChange}
            />

            {isEmpty(leadsData) && (
        <div className='flex justify-center items-center' style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>

                    <img src={emptyImage} alt='Empty Leads Data'/>
                </div>
            )}
            {!isEmpty(leadsData) && (
            <div style={{ marginBottom: '80px' }}>
                <a href={`tel:${userCallNumber}`} style={{ display: 'none' }} ref={callRef}>
                    Call
                </a>
                <div className='p-6 grid md:grid-cols-[1fr,1fr] gap-5'>
                    {leadsData.map((item, index) => (
                            <div
                                className='rounded border border-gray-300 grid grid-cols-[2px_auto] cursor-pointer'
                                key={item.id}
                            >
                                <div className='rounded-l-2xl py-4' style={{ background: getLeadTypeColor(item.lead_type) }}></div>
                                <div className='flex bg-gray-200 p-4'>
                                    <div className='flex-1' onClick={() => redirectToLead(item.id, index)}>
                                        <div>
                                            <div className='text-lg font-semibold'>
                                                {index + 1}. {item.client_name}
                                            </div>
                                            <div className='text-base text-gray-400'>{item.lead_source}</div>
                                            <div className='text-base text-gray-400'>
                                                {item.project} {item.project && <span>, </span>}
                                                {item.purpose}
                                            </div>
                                            {item.contact_by && (
                                                <div className='text-base text-gray-400'>
                                                    <strong>Contact By: </strong>
                                                    {item.contact_by}
                                                </div>
                                            )}
                                            {item.disposition && (
                                                <div className='text-base text-gray-400'>
                                                    <strong>Disposition: </strong>
                                                    {item.disposition}
                                                </div>
                                            )}
                                            {item.last_interaction && (
                                                <div className='text-base text-gray-400'>
                                                    <strong>Last interaction: </strong>
                                                    {item.last_interaction}
                                                </div>
                                            )}
                                            {item.remark && (
                                                <div className='text-base text-gray-400'>
                                                    <strong>Remark: </strong>
                                                    {item.remark}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='flex-1/2'>
                                        <div className='flex flex-col items-end'>
                                            <div>
                                                <button onClick={() => startCall(item.id, item.mobile_no, index)}>
                                                    <IoMdCall className='w-8 h-auto text-blue-500' />
                                                </button>
                                            </div>
                                            <div>
                                                <a href={`whatsapp://send?text=Hi!&phone=+${item.mobile_no}`}>
                                                    <FaWhatsapp className='w-8 h-auto mt-3 text-green-500' />
                                                </a>
                                            </div>
                                            <div>
                                                <button onClick={() => addRemark(item.id, index)}>
                                                    <IoMdChatboxes className='w-8 h-auto mt-3 text-yellow-500' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    ))}
                </div>
                {nextPageUrl && (
                    <div className='flex justify-center'>
                        <div className='text-3xl text-blue-500 cursor-pointer' onClick={handleLoadMore}>
                            <IoIosArrowDown />
                        </div>
                    </div>
                )}
            </div>
            )}

            <div className='fixed bottom-0 left-0 right-0'>
                <div
                    style={{ background: 'linear-gradient(45deg, #3167AF 0%, #00BCD4 100%)' }}
                    className='flex justify-around items-center p-2'
                >
                    <div className='flex items-center'>
                        <div className='text-white' onClick={fetchFirstTimeLeadsData}>
                            <FaHome className='text-3xl text-white mr-4 cursor-pointer' />
                        </div>
                    </div>
                    <div className='h-10 bg-gray-300 mx-4 border-r'></div>
                    <div className='flex items-center'>
                        <Link to="/call-schedules" className='text-white'>
                            <FaCalendarAlt className='text-3xl text-white mr-4 cursor-pointer' />
                        </Link>
                    </div>
                    <div className='h-10 bg-gray-300 mx-4 border-r'></div>
                    <div className='flex items-center'>
                        <Link to='/add-lead' className='text-white'>
                            <FiPlus className='text-3xl text-white mr-4 cursor-pointer' />
                        </Link>
                    </div>
                    <div className='h-10 bg-gray-300 mx-4 border-r'></div>
                    <div className='flex items-center'>
                        <Link to='/call-logs' className='text-white'>
                            <FaPhone className='text-3xl text-white cursor-pointer' />
                        </Link>
                    </div>
                </div>
            </div>
            {!isEmpty(userOnCall) && (
                <div className='fixed bottom-12 mb-3 right-6'>
                    <button
                        className='flex items-center justify-center w-12 h-12 bg-red-500 text-white rounded-full shadow-lg'
                        style={{ transform: 'rotate(135deg)' }}
                        onClick={() => {
                            redirectToLead(userOnCall.client_id);
                        }}
                    >
                        <IoMdCall className='w-6 h-6' />
                    </button>
                </div>
            )}
        </div>
);
};

export default Lead;
