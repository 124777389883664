import React, { useState, useEffect, useRef } from 'react';

import Modal from 'react-modal';
import { IoMdCall } from 'react-icons/io';
import { FaWhatsapp } from 'react-icons/fa';
import { IoMdChatboxes } from 'react-icons/io';
import { FiX } from 'react-icons/fi';
import { isEmpty } from 'lodash';
import { nextCallStore } from '../../store/nextCall';
import { useNavigate } from 'react-router-dom';
import { callHistoryUrl } from '../../config/apiEndpoints';
import { postRequest } from '../../store/common';

const NextLeads = ({ leadsData, hideNextModal }) => {
  console.log("next leads loading")

  const {setShowNextCallPopup, setSelectedDoctorIndex, setSelectedDoctorId, selectedDoctorIndex} = nextCallStore()
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [time, setTime] = useState(300);
  const [userCallNumber, setUserCallNumber] = useState(null);
  const [userOnCall, setUserOnCall] = useState(null);
  const callRef = useRef(null);

  const getLeadTypeColor = (leadType) => {
    switch (leadType) {
      case 'Hot':
        return '#FF0000'; // Red color for hot leads
      case 'Cold':
        return '#0000FF'; // Blue color for cold leads
      case 'Warm':
        return '#FFA500'; // Orange color for warm leads
      case 'Drop':
        return '#808080'; // Gray color for drop leads
      default:
        return '#FFFFFF'; // Default color for unknown lead types
    }
  };

  useEffect(() => {
    const getTime = () => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          // If the time is 1 second, close the modal
          // setIsModalOpen(false);
          hideNextModal()
        }
        return prevTime - 1;
      });
    };

    const interval = setInterval(getTime, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (time === 1) {
      setIsModalOpen(false);
    }
  }, [time]);

  const redirectToLead = (id, doctorIndex) => {
    setSelectedDoctorIndex(selectedDoctorIndex+doctorIndex)
    setSelectedDoctorId(id)
    navigate(`/lead/${id}`);
};

useEffect(() => {
  if (userCallNumber && !isEmpty(userOnCall) && callRef.current) {
      callRef.current.click();
      navigate(`/lead/${userOnCall.client_id}`);
  }
}, [userCallNumber, userOnCall, navigate]);

const startCall = async (id, mobileNo, doctorIndex) => {
  let url = `${callHistoryUrl.callHistory}`;
  let result = await postRequest(url, { body: { client_id: id }, showSuccess: 0 });
  console.log('startCall', result);
  if (!isEmpty(result)) {
      setSelectedDoctorIndex(selectedDoctorIndex+doctorIndex)
      setSelectedDoctorId(id)
      setUserOnCall(result);
      setUserCallNumber(mobileNo);
  }
};

const addRemark = async (id, doctorIndex) => {
  setSelectedDoctorIndex(selectedDoctorIndex+doctorIndex)
  setSelectedDoctorId(id)
  navigate(`/remark/${id}`);
};

const closeModal = () => {
  setShowNextCallPopup()
  setSelectedDoctorIndex() 
  setSelectedDoctorId()
  setTime(1);
};
  return (
    <>
    <a href={`tel:${userCallNumber}`} style={{ display: 'none' }} ref={callRef}>
                    Call
                </a>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Lead Information Modal"
        ariaHideApp={false} // To prevent accessibility issue
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '0',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            background: '#fff',
          },
        }}
      >
        {/* Fixed header */}
        <div
          style={{ background: 'linear-gradient(45deg, #3167AF 0%, #00BCD4 100%)' }}
          className="modal-header py-5 px-4 grid grid-rows-1 gap-5"
        >
          <p className="text-center text-white">
            <b>Next Leads</b>
            <FiX
              className="text-red-500 text-xl cursor-pointer float-right"
              onClick={closeModal}
            />
          </p>
        </div>

        <div className="modal-content">
          {leadsData.map((item, index) => (
            <div key={index} className="lead-slide p-4 pb-2 grid md:grid-cols-[1fr,1fr]">
              <div
                className="rounded border border-gray-300 grid grid-cols-[2px_auto] cursor-pointer"
                key={item.id}
              >
                <div
                  className="rounded-l-2xl py-4"
                  style={{ background: getLeadTypeColor(item.lead_type) }}
                ></div>
                <div className='flex bg-gray-200 p-4'>
                    <div className='flex-1' onClick={() => redirectToLead(item.id, index)}>
                        <div>
                            <div className='text-lg font-semibold'>
                                {index + 1}. {item.client_name}
                            </div>
                            <div className='text-base text-gray-400'>{item.lead_source}</div>
                            <div className='text-base text-gray-400'>
                                {item.project} {item.project && <span>, </span>}
                                {item.purpose}
                            </div>
                            {item.contact_by && (
                                <div className='text-base text-gray-400'>
                                    <strong>Contact By: </strong>
                                    {item.contact_by}
                                </div>
                            )}
                            {item.disposition && (
                                <div className='text-base text-gray-400'>
                                    <strong>Disposition: </strong>
                                    {item.disposition}
                                </div>
                            )}
                            {item.last_interaction && (
                                <div className='text-base text-gray-400'>
                                    <strong>Last interaction: </strong>
                                    {item.last_interaction}
                                </div>
                              )}
                              {item.remark && (
                                <div className='text-base text-gray-400'>
                                    <strong>Remark: </strong>
                                    {item.remark}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex-1/2'>
                        <div className='flex flex-col items-end'>
                            <div>
                                <button onClick={() => startCall(item.id, item.mobile_no, index)}>
                                    <IoMdCall className='w-8 h-auto text-blue-500' />
                                </button>
                            </div>
                            <div>
                                <a href={`whatsapp://send?text=Hi!&phone=+${item.mobile_no}`}>
                                    <FaWhatsapp className='w-8 h-auto mt-3 text-green-500' />
                                </a>
                            </div>
                            <div>
                                <button onClick={() => addRemark(item.id, index)}>
                                    <IoMdChatboxes className='w-8 h-auto mt-3 text-yellow-500' />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Fixed footer */}
        <div
          style={{ background: 'linear-gradient(45deg, #3167AF 0%, #00BCD4 100%)' }}
          className="modal-footer py-5 px-4"
        >
          <p className="text-center text-white">
            <b>{time}</b>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default NextLeads;
