import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {navigateStore} from '../../store/common'
const NavigateTo = () => {
  const {navigate, updateNavigate} = navigateStore()
  const navigateTo = useNavigate();
  useEffect(() => {
    if(navigate)
    {
      navigateTo(navigate);
      updateNavigate(null)
    }
  }, [navigate,updateNavigate, navigateTo]);  
  return (
    <>
    </>
  );
};

export default NavigateTo;

