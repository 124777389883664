import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { sources, leadTypes, dispositions, budgets, telecallers, projects, subDispositions } from '../../helpers/masterApi';

const FilterSidebar = ({ showSidebar, onToggleSidebar, onHandleFilterChange }) => {
  const inputClassName =
    'w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500 bg-transparent';

  const toggleSidebar = () => {
    onToggleSidebar();
  };

  if (sources[0].id !== '') {
    sources.unshift({ id: '', name: 'Select' });
  }
  if (leadTypes[0].id !== '') {
    leadTypes.unshift({ id: '', name: 'Select' });
  }
  if (dispositions[0].id !== '') {
    dispositions.unshift({ id: '', name: 'Select' });
  }
  if (subDispositions[0].id !== '') {
    subDispositions.unshift({ id: '', name: 'Select' });
  }
  if (budgets[0].id !== '') {
    budgets.unshift({ id: '', name: 'Select' });
  }
  if (telecallers[0].id !== '') {
    telecallers.unshift({ id: '', full_name: 'Select' });
  }
  if (projects[0].id !== '') {
    projects.unshift({ id: '', project_name: 'Select' });
  }

  const [fromDate, setFromDate] = useState('');
  const [lastInteractionFromDate, setLastInteractionFromDate] = useState('');
  
  const [toDate, setToDate] = useState('');
  const [lastInteractionToDate, setLastInteractionToDate] = useState('');

  const [source, setSource] = useState('');
  const [leadType, setLeadType] = useState('');
  const [disposition, setDisposition] = useState('');
  const [subDisposition, setSubDisposition] = useState('');
  const [budget, setBudget] = useState('');
  const [attendStatus, setAttendStatus] = useState('');
  const [attempted, setAttempted] = useState('');
  const [project, setProject] = useState('');
  const [search, setSearch] = useState('');
  
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  const handleLastInteractionFromDateChange = (e) => {
    setLastInteractionFromDate(e.target.value);
  };
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const handleLastInteractionToDateChange = (e) => {
    setLastInteractionToDate(e.target.value);
  };
  const handleSourceChange = (e) => {
    setSource(e.target.value);
  };

  const handleLeadTypeChange = (e) => {
    setLeadType(e.target.value);
  };

  const handleDispositionChange = (e) => {
    setDisposition(e.target.value);
  };

  const handleSubDispositionChange = (e) => {
    setSubDisposition(e.target.value);
  };

  const handleBudgetChange = (e) => {
    setBudget(e.target.value);
  };

  const handleAttendStatusChange = (e) => {
    setAttendStatus(e.target.value);
  };

  const handleAttemptedChange = (e) => {
    setAttempted(e.target.value);
  };

  const handleProjectChange = (e) => {
    setProject(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filter = {
      fromDate,
      toDate,
      lastInteractionFromDate,
      lastInteractionToDate,
      source,
      leadType,
      disposition,
      subDisposition,
      budget,
      attendStatus,
      attempted,
      project,
      search,
    };
    localStorage.setItem('filter', JSON.stringify(filter));
    onHandleFilterChange();
  };

  const handleClearFilter = () => {
    setFromDate('');
    setToDate('');
    setLastInteractionFromDate('');
    setLastInteractionToDate('');
    setSource('');
    setLeadType('');
    setDisposition('');
    setSubDisposition('');
    setBudget('');
    setAttendStatus('');
    setAttempted('');
    setProject('');
    setSearch('');
    localStorage.removeItem('filter');
    onHandleFilterChange();
  };

  useEffect(() => {
    // Fetch the filter values from localStorage
    const storedFilter = localStorage.getItem('filter');
    if (storedFilter) {
      const filter = JSON.parse(storedFilter);
      setFromDate(filter.fromDate || '');
      setToDate(filter.toDate || '');
      setLastInteractionFromDate(filter.lastInteractionFromDate || '');
      setLastInteractionToDate(filter.lastInteractionToDate || '');
      setSource(filter.source || '');
      setLeadType(filter.leadType || '');
      setDisposition(filter.disposition || '');
      setSubDisposition(filter.subDisposition || '');
      setBudget(filter.budget || '');
      setAttendStatus(filter.attendStatus || '');
      setAttempted(filter.attempted || '');
      setProject(filter.project || '');
      setSearch(filter.search || '');
    }
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 bottom-0 z-50 bg-white transition-transform duration-300 ${
        showSidebar ? 'transform translate-x-0' : 'transform -translate-x-full'
      }`}
    >
      <div className="p-4">
        <p className="text-center">
          <b>ADVANCE FILTERS</b>
          <FiX
            className="text-red-500 text-xl cursor-pointer float-right"
            onClick={toggleSidebar}
          />
        </p>
      </div>

      {/* Sidebar content */}
      <div className="p-4 h-full overflow-y-auto">
        <form onSubmit={handleSubmit}>
          <div>
            <div className="p-3">
              <h2 className="mb-2">Leads From</h2>
              <input
                type="date"
                name="from_date"
                value={fromDate}
                onChange={handleFromDateChange}
                id="from_date"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <h2 className="mb-2">Leads To</h2>
              <input
                type="date"
                name="to_date"
                value={toDate}
                onChange={handleToDateChange}
                id="to_date"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <h2 className="mb-2">Source</h2>
              <select
                name="source"
                value={source}
                onChange={handleSourceChange}
                id="source"
                className={inputClassName}
              >
                {sources.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Lead Type</h2>
              <select
                name="lead_type"
                value={leadType}
                onChange={handleLeadTypeChange}
                id="lead_type"
                className={inputClassName}
              >
                {leadTypes.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Disposition</h2>
              <select
                name="disposition"
                value={disposition}
                onChange={handleDispositionChange}
                id="disposition"
                className={inputClassName}
              >
                {dispositions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Sub Disposition</h2>
              <select
                name="sub_disposition"
                value={subDisposition}
                onChange={handleSubDispositionChange}
                id="sub_disposition"
                className={inputClassName}
              >
                {subDispositions.map((option) => (
                  <option key={option.id} value={option.id}>
                   {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Budget</h2>
              <select
                name="budget"
                value={budget}
                onChange={handleBudgetChange}
                id="budget"
                className={inputClassName}
              >
                {budgets.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Attended Status</h2>
              <select
                name="budget"
                value={attendStatus}
                onChange={handleAttendStatusChange}
                id="attend_status"
                className={inputClassName}
              >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Last Interaction From</h2>
              <input
                type="date"
                name="from_date"
                value={lastInteractionFromDate}
                onChange={handleLastInteractionFromDateChange}
                id="from_date"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <h2 className="mb-2">Last Interaction To</h2>
              <input
                type="date"
                name="to_date"
                value={lastInteractionToDate}
                onChange={handleLastInteractionToDateChange}
                id="to_date"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <h2 className="mb-2">No of Times Attempted</h2>
              <input
                type="number"
                name="attempted"
                value={attempted}
                onChange={handleAttemptedChange}
                id="attempted"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <h2 className="mb-2">Project</h2>
              <select
                name="project"
                value={project}
                onChange={handleProjectChange}
                id="project"
                className={inputClassName}
              >
                {projects.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.project_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="p-3">
              <h2 className="mb-2">Search</h2>
              <input
                type="text"
                name="search_query"
                value={search}
                onChange={handleSearchChange}
                id="search_query"
                className={inputClassName}
              />
            </div>

            <div className="p-3">
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md"
              >
                Apply Filters
              </button>
            </div>
          </div>
        </form>

        <div className="p-3 pb-5">
          <button
            onClick={handleClearFilter}
            className="w-full py-2 px-4 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-md mb-10"
          >
            Clear Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSidebar;
