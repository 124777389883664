import React from 'react';
import NavigateTo from '../../components/common/NavigateTo';
import { ToastContainer } from 'react-toastify';
import Loading from '../../components/common/Loader';
const MasterLayout = ({ children }) => {
console.log("masterLayout loading...");
  return (
    <div className="master-layout">
      <header>
        {/* Your header content goes here */}
      </header>
      <main>
        {children}
      </main>
      <footer>
      </footer>
      {<NavigateTo />}
      {<Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default MasterLayout;
