import React, { useState, useEffect } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { subDays } from 'date-fns';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { IoMdCall } from 'react-icons/io';
import { remarkUrl } from '../../config/apiEndpoints';
import { getRequest } from '../../store/common';

const CallLogs = () => {
  const [filter, setFilter] = useState('today');
  const [callLogsData, setCallLogsData] = useState([]);
  
  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
    fetchCallLogs(selectedFilter)
  };

  const filterOptions = [
    { label: 'Today', value: 'today' },
    { label: 'Last 7 days', value: 'last7' },
    { label: 'Last 15 days', value: 'last15' },
    { label: 'Last 30 days', value: 'last30' },
  ];

  useEffect(() => {
    fetchCallLogs();
  }, []);

  const fetchCallLogs = async (dateFilter="") => {
    let start_date = "";
    if (dateFilter === 'last7') {
      start_date = subDays(new Date(), 7);
    } else if (dateFilter === 'last15') {
      start_date = subDays(new Date(), 15)
    } else if (dateFilter === 'last30') {
      start_date = subDays(new Date(), 30)
    }
    
    let url = remarkUrl.byTelecaller;
    const response = await getRequest(url, {body:{start_date}});
    console.log(response, "response")
    setCallLogsData(response.data);
  };

  return (
    <div className='md:mx-auto max-w-maxContainer'>
      <div
        style={{ background: 'linear-gradient(90deg, #0663C0 0%, #0541BB 14.6%, #0541BB 82.01%, #0663C0 100%)' }}
        className='p-4 grid gap-4'
      >
        <div className='grid gap-2.5'>
          <div className='flex justify-between items-center'>
            <Link to="/leads" className='flex items-center text-white'>
              <AiOutlineArrowLeft className='text-white text-2xl font-bold mr-2 cursor-pointer' />
              <span>Call History</span>
            </Link>
            <div className='font-normal text-md text-white'>Total: {callLogsData.length}</div>
          </div>
        </div>
      </div>
      <div className='bg-white'>
        <div className='flex items-center justify-center gap-1 mt-4'>
          {filterOptions.map(option => (
            <div
              key={option.value}
              className={`text-sm font-medium cursor-pointer rounded-full px-3 py-1 ${
                filter === option.value ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-800'
              }`}
              onClick={() => handleFilterChange(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
        <div className='px-6 py-4'>
          {callLogsData.map((item, index) => (
            <Link to={`/lead/${item.client_id}`}
              key={index}
              className='border border-gray-200 rounded-lg px-4 py-3 mb-4 flex items-center'
            >
              <div className='flex-shrink-0'>
                <IoMdCall className='w-10 h-10 text-blue-500' />
              </div>
              <div className='ml-4'>
                <div className='font-semibold'>{item?.client}</div>
                <div className='text-gray-500'>{item?.remark}</div>
                <div className='text-gray-500'>{item?.disposition}</div>
                <div className='text-sm text-gray-400'>
                  At {item?.contacted_date}
                </div>
              </div>
            </Link>
          ))}
          {isEmpty(callLogsData) && (
            <div className='text-gray-500 text-center mt-6'>
              No call history found.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallLogs;
