export const purposes = [
    { value: '', label: 'Select' },
    { value: '1st_home', label: '1st Home' },
    { value: 'weekend', label: 'Weekend Home' },
    { value: 'investment', label: 'Investment' },
    { value: 'rental', label: 'Rental Property' },
    { value: 'retirement', label: 'Retirement Home' },
    { value: 'vacation', label: 'Vacation Home' },
  ];


export const mediums = [
    { value: '', label: 'Select' },
    { value: 'digital', label: 'Digital' },
    { value: 'hoarding', label: 'Hoarding' },
    { value: 'newspaper', label: 'Newspaper' },
    { value: 'others', label: 'Others' }
  ];
