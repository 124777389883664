import React, { useState, useRef, useEffect } from 'react';
import { MdOutlinePhoneCallback, MdOutlineWhatsapp } from 'react-icons/md';
import { Link, useNavigate} from 'react-router-dom';
import { isEmpty } from 'lodash';
import ContentTab from './Tab/ContentTab';
import ProjectTab from './Tab/ProjectTab';
import RemarkTab from './Tab/RemarkTab';
import ProfileTab from './Tab/ProfileTab';
import { postRequest, getRequest } from '../../store/common';
import { callHistoryUrl } from '../../config/apiEndpoints';
import { IoMdChatboxes } from 'react-icons/io';
import { toast } from 'react-toastify';

const SingleLead = () => {
  const navigateTo = useNavigate();
  const [active, setActive] = useState('Profile');
  const [userOnCall, setUserOnCall] = useState(null);
  const [singleLead, setSingleLead] = useState({});
  const [userCallingTime, setUserCallingTime] = useState("");
  
  const callRef = useRef(null);

  const handleLeadChange = async (lead) => {
    setSingleLead(lead);
    await checkUserOnCall();
  };

  const startCall = async() => {
    let url = `${callHistoryUrl.callHistory}`;
    let result = await postRequest(url, {body:{client_id:singleLead.id}, showSuccess:0});
    console.log("startCall", result)
    if(!isEmpty(result))
    {
      setUserOnCall(result);
      callRef.current.click();
    }
  };


  const checkUserOnCall = async () => {
    let url = callHistoryUrl.checkUserOnCall;
    let user = await getRequest(url);
    // console.log(!isEmpty(user) && !isEmpty(user) ,user?.client_id ,singleLead.id)
    if (!isEmpty(user) && !isEmpty(singleLead) & user?.client_id !== singleLead.id) {
      navigateTo("/leads");
    }
    setUserOnCall(user);
  };

  useEffect(() => {
    const updateUserCallingTime = () => {
      setUserCallingTime(getTimeDifference(userOnCall?.start_time));
    };
  
    const getTimeDifference = (givenTime) => {
      const currentTime = new Date(); // Current time
      const givenTimeObj = new Date(givenTime); // Given time
    
      // Calculate the time difference in milliseconds
      const timeDifference = currentTime.getTime() - givenTimeObj.getTime();
    
      // Convert the time difference to h:m:s format
      const seconds = Math.floor(timeDifference / 1000) % 60;
      const minutes = Math.floor(timeDifference / 1000 / 60) % 60;
      const hours = Math.floor(timeDifference / 1000 / 60 / 60);
    
      // Format the time difference as hh:mm:ss
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      const formattedTimeDifference = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    
      return formattedTimeDifference;
    };
  
    const interval = setInterval(updateUserCallingTime, 1000);
  
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [userOnCall]);
  const addRemark = async () => {
    if (!isEmpty(userOnCall)) {
      toast.error('Please dispose your first call.');
      return;
    }
    navigateTo(`/remark/${singleLead.id}`);
  };

  return (
      <div className='md:mx-auto max-w-maxContainer'>
        <div style={{ background: 'linear-gradient(90deg, #0663C0 0%, #0541BB 14.6%, #0541BB 82.01%, #0663C0 100%)' }} className='p-4 grid gap-4'>
          <div className='grid gap-2.5'>
            <div className='flex justify-between'>
              <div className='flex justify-start'>
                <div className='font-normal text-md text-white'></div>
              </div>
            </div>
          </div>
          {!isEmpty(singleLead) && (
            <div className='grid justify-items-center'>
              <div className='text-lg text-white font-semibold'>{singleLead?.client_name}</div>
              <div className='text-white text-gray-400'>
                {singleLead.project}
                {singleLead.project && <span>, </span>}
                {singleLead.purpose}
              </div>
              {!isEmpty(singleLead.last_interaction) && (
                <div className='text-white text-gray-400'>Last interaction: {singleLead?.last_interaction}</div>
              )}
            </div>
          )}
          <div className='flex justify-between mt-3 md:mx-auto max-w-maxContainer'>
            <div className='grid justify-items-center gap-2 cursor-pointer text-center'>
              <a href={`whatsapp://send?text=Hi!&phone=${singleLead?.mobile_no}`}>
                <div className='bg-white rounded-full h-12 w-12 flex items-center justify-center md:mx-20'>
                  <MdOutlineWhatsapp className='text-2xl text-blue-600' />
                </div>
                <span className='text-white text-xs font-semibold'>Whatsapp</span>
              </a>
            </div>
            <div className='grid justify-items-center gap-2 cursor-pointer text-center'>
              <button onClick={addRemark}>
                <div className='bg-white rounded-full h-12 w-12 flex items-center justify-center md:mx-20'>
                  <IoMdChatboxes className='text-2xl text-blue-600' />
                </div>
                <span className='text-white text-xs font-semibold'>Remark</span>
              </button>
            </div>
            {!userOnCall && (
              <div className='grid justify-items-center gap-2 cursor-pointer text-center'>
                <a href={`tel:${singleLead?.mobile_no}`} style={{ display: 'none' }} ref={callRef}>
                  Call
                </a>
                <div onClick={startCall}>
                  <div className='bg-white rounded-full h-12 w-12 flex items-center justify-center md:mx-20'>
                    <MdOutlinePhoneCallback className='text-2xl text-blue-600' />
                  </div>
                  <span className='text-white text-xs font-semibold'>Call</span>
                </div>
              </div>
            )}
            {userOnCall && (
              <div className='grid justify-items-center gap-2 cursor-pointer'>
                <div className='grid justify-items-center'>
                  <div className='block flex justify-between items-center w-full h-14 p-2 rounded-lg mt-1 b' style={{ background: 'rgba(255, 255, 255, 0.14)' }}>
                    <span className='text-base font-normal text-gray-100 end-call-time'>{userCallingTime}</span>
                    <Link to="/end-call">
                      <button className='bg-red-500 text-sm font-semibold text-gray-100 rounded-xl py-2 px-4 ml-2'>End Call</button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='h-screen md:h-full bg-[#d2effd]'>
          <div className='flex gap-3 px-2 md:grid md:grid-cols-3 bg-white'>
            <div className={`${active === 'Profile' ? 'border-blue-500 border-b-2 text-black' : 'text-gray-400'} font-normal cursor-pointer md py-2 px-4 md:invisible`} onClick={() => setActive('Profile')}>Profile</div>
            <div className={`${active === 'Content' ? 'border-blue-500 border-b-2 text-black md:font-medium' : 'text-gray-400'} font-normal cursor-pointer py-2 px-4 md:border-none`} onClick={() => setActive('Content')}>Content</div>
            <div className={`${active === 'Remark' ? 'border-blue-500 border-b-2 text-black md:font-medium' : 'text-gray-400'} font-normal cursor-pointer py-2 px-4 md:border-none`} onClick={() => setActive('Remark')}>Remarks</div>
            <div className={`${active === 'Project' ? 'border-blue-500 border-b-2 text-black md:font-medium' : 'text-gray-400'} font-normal cursor-pointer py-2 px-4 md:border-none`} onClick={() => setActive('Project')}>Project</div>
          </div>
          <div className='bg-[#d2effd]'>
            <div className='md:grid md:grid-cols-[350px,1fr] md:items-start'>
              <div>
                {active === 'Profile' && <ProfileTab onLeadChange={handleLeadChange} />}
                {active === 'Content' && <ContentTab />}
                {active === 'Remark' && <RemarkTab />}
                {active === 'Project' && <ProjectTab />}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default SingleLead;
