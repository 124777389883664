let masterUrl = "/master";
export const getDoctors = '/get-doctor-list-by-field-rep?nopagination';

export const getFieldRepContent = '/get-field-rep-content?nopagination';

export const getSingleDoctor = '/get-single-doctor-by-field-rep';

export const login = '/field-rep-login';

export const checkLogin = '/field-rep-login-check';

export const getDetail1 = '/get-detail-one-by-field-rep?nopagination';

export const getBrandDetails = '/get-brand-forum-details-by-field-rep';





export const getSourcesUrl = masterUrl+'/get-sources';
export const getLeadTypes = masterUrl+'/get-lead-types';
export const getDispositions = masterUrl+'/get-dispositions';
export const getBudgets = masterUrl+'/get-budgets';
export const getTelecallers = masterUrl+'/get-telecallers';
export const getProjects = masterUrl+'/get-projects';
export const getSubDispositions = masterUrl+'/get-sub-dispositions';
export const getLookingFor = masterUrl+'/get-looking-for';
export const getLocations = masterUrl+'/get-locations';

export const checkIsLoggedInUrl = '/is-logged-in';
export const loginUrl = '/login';
export const leadUrl = {
    "lead":'/lead',
    "byTelecaller":'/lead/get-by-telecaller',
    "nextLeads":'/lead/get-by-telecaller?nopagination'
};
export const callHistoryUrl = {
    "callHistory":'/call-history',
    "checkUserOnCall":'/check-user-on-call',    
};

export const remarkUrl = {
    "remark":'/remark',
    "byTelecaller":'/remark/get-by-telecaller?nopagination',
    "byClient":'/remark/get-by-client?nopagination'
};


export const schedulesCallsUrl = {
    "reminder":'/reminder'
};