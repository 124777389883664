import axios from 'axios';
import { isEmpty } from 'lodash';
let baseUrl = process.env.REACT_APP_BACKEND_URL;
const axiosDefaultInstance = axios.create({
	baseURL: baseUrl,
	mode: "no-cors",
});
const headers = (token)=> {
	return {
		headers: {
			Authorization: `Bearer ${token}`
		}
	}
}
export const request = (url, req, params) => {
	let TOKEN = localStorage.getItem('token');
	let body = params?.body || {};
	let requestUrl = url;
	if (req === "get" && !isEmpty(body)) {
		const bodyQueryString = body ? Object.keys(body)
			.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
			.join('&') : '';
		if (bodyQueryString) {
			requestUrl += (url.includes('?') ? '&' : '?') + bodyQueryString;
		}
	}
	if (req === "get" || req === "delete") {
		return axiosDefaultInstance[req](requestUrl, headers(TOKEN));
	}
	else {
		return axiosDefaultInstance[req](requestUrl, body || {}, headers(TOKEN));
	}
};