import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Ratings from './Ratings';
import { Link, useParams } from 'react-router-dom';
import { leadTypes, dispositions, subDispositions } from '../../helpers/masterApi';
import { putRequest, getRequest, deleteRequest, postRequest } from '../../store/common';
import { callHistoryUrl, remarkUrl, leadUrl } from '../../config/apiEndpoints';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { nextCallStore } from '../../store/nextCall';

const DisposeCall = React.forwardRef((props, ref) => {
  const [raiseRequest, setRaiseRequest] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [subDisposition, setSubDisposition] = useState('');
  const [disposition, setDisposition] = useState('');
  const [leadType, setLeadType] = useState('');
  const [remark, setRemark] = useState('');
  const [rating, setRating] = useState('');
  const [userOnCall, setUserOnCall] = useState(null);
  const navigateTo = useNavigate();
  const { clientId } = useParams();
  const {selectedDoctorIndex, setShowNextCallPopup, setSelectedDoctorIndex, selectedDoctorId, setSelectedDoctorId} = nextCallStore()
  const [lead, setLead] = useState({});
  useEffect(() => {
    const checkUserOnCall = async () => {
      let url = callHistoryUrl.checkUserOnCall;
      let user = await getRequest(url);
      if(isEmpty(user) && isEmpty(clientId))
      {
        navigateTo("/leads")
      }
      console.log(user)
      let id = isEmpty(user) ? clientId : user.client_id 
      fetchLead(id)
      setUserOnCall(user);

    };
    checkUserOnCall();
  }, [navigateTo, clientId]);
  const fetchLead = async (id) => {
    let url = `${leadUrl.lead}/${id}`;
    let temp = await getRequest(url);
    setLead(temp);
};

  const inputClassName =
    'w-full bg-white rounded-full p-4 mb-3 border border-gray-300 focus:outline-none';

  if (dispositions[0].id !== '') {
    dispositions.unshift({ id: '', name: 'Select Disposition' });
  }
  if (subDispositions[0].id !== '') {
    subDispositions.unshift({ id: '', name: 'Select Sub Disposition' });
  }
  if (leadTypes[0].id !== '') {
    leadTypes.unshift({ id: '', name: 'Select Lead Type' });
  }

  const handleCheckboxChange = (event) => {
    setRaiseRequest(event.target.checked);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const handleDispositionChange = (e) => {
    setDisposition(e.target.value);
  };

  const handleSubDispositionChange = (e) => {
    setSubDisposition(e.target.value);
  };

  const handleLeadTypeChange = (e) => {
    setLeadType(e.target.value);
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div>
      <button
        ref={ref}
        className="bg-[#0b5ee0] rounded-full p-4 mb-2 focus:outline-none text-white"
        onClick={onClick}
        style={{ minWidth: '200px' }}
      >
        {value || 'Schedule Next Call'}
      </button>
      {value && (
        <button
          style={{ color: 'white', marginLeft: '10px' }}
          className="bg-[#fe0000] rounded-full p-4 mb-2 focus:outline-none text-white"
          onClick={handleClearDate}
        >
          X
        </button>
      )}
    </div>
  ));

  const endCall = async () => {
    let body = {
      deposition_id: disposition,
      lead_types_id: leadType,
      sub_deposition_id: subDisposition,
      remark,
      raise_request: raiseRequest,
      schedule_date: selectedDate,
      rating,
    };
    if(isEmpty(clientId))
    {
      let url = `${callHistoryUrl.callHistory}/${userOnCall.id}`;
      await putRequest(url, { body, redirectOnSuccessUrl: "/leads", successCallBack:endCallSuccessCallBack });
    }
    else{
      let url = `${remarkUrl.remark}`;
      body.client_id = clientId
      await postRequest(url, { body, redirectOnSuccessUrl: "/leads", successCallBack:endCallSuccessCallBack });
    }
  };
  const dontSaveCall = async () => {
    let url = `${callHistoryUrl.callHistory}/${userOnCall.id}`;
    await deleteRequest(url, { redirectOnSuccessUrl: "/leads", clearBackHistory: true, successCallBack:endCallSuccessCallBack });
  };
  
  const endCallSuccessCallBack = function(){
    console.log(selectedDoctorIndex && (selectedDoctorId === userOnCall?.client_id || selectedDoctorId === parseInt(clientId)),selectedDoctorIndex , selectedDoctorId, userOnCall?.client_id, clientId)
    if(selectedDoctorIndex && (selectedDoctorId === userOnCall?.client_id || selectedDoctorId === parseInt(clientId)))
    {
        setShowNextCallPopup(true)
    }
    else{
      setShowNextCallPopup()
      setSelectedDoctorIndex() 
      setSelectedDoctorId()
    }
  }

  return (
      <div className="bg-[#011133] p-4">
        <div className="grid grid-cols-1 gap-4">
          <div className="mt-5">
            <select
              name="disposition"
              value={disposition}
              onChange={handleDispositionChange}
              id="disposition"
              className={inputClassName}
            >
              {dispositions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="sub_disposition"
              value={subDisposition}
              onChange={handleSubDispositionChange}
              id="sub_disposition"
              className={inputClassName}
            >
              {subDispositions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              name="lead_type"
              value={leadType}
              onChange={handleLeadTypeChange}
              id="lead_type"
              className={inputClassName}
            >
              {leadTypes.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <textarea
              id="notes"
              placeholder="Notes"
              className="w-full bg-white rounded-lg p-4 mb-3 border border-gray-300 focus:outline-none resize-none"
              rows={5}
              onChange={handleRemarkChange}
            ></textarea>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="raiseRequest"
              checked={raiseRequest}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="raiseRequest" style={{ color: 'white' }}>
              Raise Request
            </label>
          </div>
          <div className="flex justify-self-center text-white">
            <Ratings onRatingChange={(rating) => setRating(rating)} />
          </div>
          <div className="flex justify-self-center">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              dateFormat="d MMM yyyy h:mm aa"
              customInput={<CustomInput ref={ref} />}
              placeholderText="Schedule Next Call"
            />
          </div>
          <div className="flex justify-self-center">
            <a
              href={`whatsapp://send?text=Hi!&phone=${lead?.mobile_no}`}
              style={{ color: 'white', minWidth: '200px' }}
              className="bg-[#00bcd5] rounded-full p-4 mb-2 focus:outline-none text-white text-center"
            >
              Send WhatsApp
            </a>
          </div>
          <div className="flex justify-self-center">
            <button
              style={{ color: 'white', minWidth: '200px' }}
              className="bg-[#01bb3e] rounded-full p-4 mb-2 focus:outline-none text-white"
              onClick={endCall}
            >
              Save
            </button>
          </div>
          {isEmpty(clientId) && (
          <div className="flex justify-self-center">
            <button
              style={{ color: 'white', minWidth: '200px' }}
              className="bg-[#fe0000] rounded-full p-4 mb-2 focus:outline-none text-white"
              onClick={dontSaveCall}
            >
              Don't Save Call
            </button>
          </div>)}
          <div className="flex justify-self-center">
            <Link
              to={`/leads`}
              style={{ color: 'white', minWidth: '200px' }}
              className="rounded-full p-4 mb-2 border border-gray-300 focus:outline-none text-white text-center"
            >
              Close
            </Link>
          </div>
        </div>
      </div>
  );
});

export default DisposeCall;
