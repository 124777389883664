import React from 'react';
import { loaderStore } from '../../store/common';
const Loader = () => {
  const { loading } = loaderStore();
  console.log("loader loading...", loading);
  return (
    <>
    {loading!==0 && (
    <div className="fullscreen-loader">
      <div className="dots-container">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
    )}
    </>
  );
};

export default Loader;