import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { remarkUrl } from '../../../config/apiEndpoints';
import { getRequest } from '../../../store/common';
import telecalerImg from '../../../assets/images/telecaller.png';
import { useParams } from 'react-router-dom';

const RemarkTab = () => {
  const [remarksData, setRemarkData] = useState([]);
  const { id } = useParams();


  useEffect(() => {
    const fetchRemarksData = async () => {
      let url = remarkUrl.byClient;
      const response = await getRequest(url, { body: { client_id: id } });
      setRemarkData(response.data);
    };
  
    fetchRemarksData();
  }, [id]);


  return (
    <div className='bg-white m-4'>
      <div className='px-6 py-4'>
        {remarksData.map((item, index) => (
          <div
            key={index}
            className='border border-gray-200 rounded-lg px-4 py-3 mb-4 flex items-center'
          >
            <div className='flex-shrink-0'>
              <img
              src={telecalerImg}
                alt='Content Placeholder'
                className='w-10 h-10 rounded-full'
              />
            </div>
            <div className='ml-4'>
              <div className='font-semibold'>{item.telecaller}</div>
              <div className='text-sm text-gray-400'>
                <strong>Lead Type: </strong>
                {item.lead_types}
              </div>
              <div className='text-sm text-gray-400'>
                <strong>Disposition: </strong>
                {item.disposition}
              </div>
              <div className='text-sm text-gray-400'>
                <strong>Remark: </strong>
                {item.remark}
              </div>
              <div className='text-sm text-gray-400'>
                <strong>Contact Date: </strong>
                {item.contacted_date}
              </div>
            </div>
          </div>
        ))}
        {isEmpty(remarksData) && (
          <div className='text-gray-500 text-center mt-6'>
            No remark found.
          </div>
        )}
      </div>
    </div>
  );
};

export default RemarkTab;
