import { create } from 'zustand';
import { request } from '../config/axiosInterceptor';
import { toast } from 'react-toastify';

export const navigateStore = create((set) => ({
  navigate: "",
  updateNavigate: (url) => set((state) => ({ navigate: url })),
  // unSetNavigate: () => set((state) => ({ navigate: 0 })),
}));

export const loaderStore = create((set) => ({
  loading: 0,
  toggleLoading: () => set((state) => ({ loading: !state.loading })),
  setLoading: () => set((state) => ({ loading: 1 })),
  unSetLoading: () => set((state) => ({ loading: 0 })),
}));

const fetchData = async (url, req, params = {}) =>{
  console.log('Fetching data', url, req, params)
  params = {...{showSuccess:1, showError:1}, ...params}
  const { setLoading, unSetLoading } = loaderStore.getState();
  const { updateNavigate } = navigateStore.getState();
  try {
    setLoading()
    let response = await request(url, req, params)    
    const data = await response.data;
    console.log(data, req, params, "data respoonse")
    if (data?.status) {
      if(params?.successCallBack)
      {
        params.successCallBack()
      }
      if((req !== "get") && params?.showSuccess === 1)
      {
        toast.success(data.message);
      }
      if(params?.redirectOnSuccessUrl)
      {
        url = params.redirectOnSuccessUrl
        // if(params?.clearBackHistory)
        // {
        //   setTimeout(() => {
        //     window.location.replace(url);
        //   }, 3000);
        // }
        // else{
        //   updateNavigate(url)
        // }
        updateNavigate(url)
      }
      return data.response
    }
    else{
      toast.error(data.message);
    }
  } catch (error) {
    console.log(error)
    let showError = params?.showError || 0
    let msg = "Error"
    let status = error?.request?.status 
    if(status===422)
    {
      msg = error.response.data.response
    }
    else if(status===500)
    {
      msg = error.response.data.message
    }
    else if(status===429)
    {
      msg = "To many Requests"
    }
    else if(status===401)
    {
      msg = "Please Login"
    }
    else if(status===401)
    {
      msg = "Please Login"
    }
    else{
      msg = 'Something went wrong'
      updateNavigate("/")
    }
    console.log(msg)
    if(showError)
    {
      toast.error(msg);
    }
  } finally {
    unSetLoading()
  }
  return false
}
export const getRequest = async (url, params = {}) =>{
  return await fetchData(url, "get", params)
}

export const putRequest = async (url, params = {}) =>{
  return await fetchData(url, "put", params)
}

export const postRequest = async (url, params = {}) =>{
  return await fetchData(url, "post", params)
}
export const deleteRequest = async (url, params = {}) =>{
  return await fetchData(url, "delete", params)
}