import React from 'react';

const ProjectTab = () => {

   return (
     
      
      <div className="bg-white m-4">
      <div className="px-6 py-4">
      <p className="mb-4">Welcome to our exclusive community of modern living! Discover a haven of comfort and luxury at [Community Name], where every corner is designed to exceed your expectations. Our project offers an unparalleled blend of quality construction, exquisite design, and a vibrant neighborhood that will make your dream of owning a home a reality.</p>
<p className="mb-4"><b>Key Features:</b></p>
<p className="mb-4"><b>Contemporary Homes:</b> Our thoughtfully crafted homes are designed to provide the perfect balance between style and functionality. Enjoy spacious floor plans, high ceilings, and large windows that fill your living space with natural light.</p>
<p className="mb-4"><b>Prime Location:</b> Nestled in a coveted neighborhood, our project offers easy access to major highways, shopping centers, restaurants, and entertainment venues. Experience the convenience of urban living combined with the tranquility of a serene environment.</p>
<p className="mb-4"><b>Amenities for Every Lifestyle:</b> We believe in providing a holistic living experience. Indulge in a range of amenities, including a state-of-the-art fitness center, sparkling swimming pool, landscaped gardens, children's play area, and a clubhouse for social gatherings.</p>
<p className="mb-4"><b>Quality Construction:</b> We are committed to delivering homes built with the highest standards of quality and craftsmanship. From the foundation to the finishing touches, attention to detail is our hallmark.</p>
<p className="mb-4">Sustainable Living: Embrace an eco-friendly lifestyle with our project's sustainable features. Energy-efficient appliances, rainwater harvesting systems, and landscaped green spaces contribute to a greener environment for generations to come.</p>
<p className="mb-4"><b>Safety and Security:</b> Your peace of mind is our priority. Our community is equipped with round-the-clock security measures, including CCTV surveillance, gated entry, and trained personnel, ensuring a safe and secure living environment.</p>
<p className="mb-4"><b>Excellent Schools:</b> For families with children, our project is located in close proximity to renowned schools and educational institutions, providing the best opportunities for their future.</p>
<p className="mb-4"><b>Personalized Home Customization:</b> We understand that every homeowner is unique. With our customizable options, you have the freedom to personalize your living space to suit your taste and preferences.</p>
<p className="mb-4"><b>Transparent Pricing:</b> We believe in maintaining transparency in all our dealings. Our pricing structure is straightforward, ensuring you get the best value for your investment.</p>
<p className="mb-4"><b>Expert Customer Service:</b> Our dedicated customer service team is committed to assisting you throughout your home buying journey. From initial inquiries to post-purchase support, we are here to make your experience seamless and enjoyable.</p>
<p className="mb-4">Don't miss the opportunity to be part of this exceptional community. Register your interest today and take the first step towards owning your dream home at [Community Name]!</p>
      </div>
    </div>

   )
}

export default ProjectTab
