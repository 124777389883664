import React, { useState, useEffect } from 'react';
import { FaPhone } from 'react-icons/fa';
import './CallSchedules.css';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { postRequest } from '../../store/common';
import { schedulesCallsUrl } from '../../config/apiEndpoints';

const CallSchedules = React.forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [scheduleData, setScheduleData] = useState(null);


  useEffect(() => {
    const fetchSchedulesCalls = async () => {
      let url = schedulesCallsUrl.reminder;
      const response = await postRequest(url, {body:{start_date:selectedDate}, showSuccess:0});
      setScheduleData(response);
    };
    fetchSchedulesCalls();
  }, [selectedDate]);

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div>
      <button
        ref={ref}
        className="rounded-full p-4 m-4 mb-0 focus:outline-none text-white"
        onClick={onClick}
        style={{ minWidth: '200px', background: 'linear-gradient(45deg, #59c2e5, #007bff)' }}
      >
        {value || 'Filter By Date'}
      </button>
      {value && (
        <button
          style={{ color: 'white', marginLeft: '10px' }}
          className="bg-[#fe0000] rounded-full p-4 mb-2 focus:outline-none text-white"
          onClick={handleClearDate}
        >
          X
        </button>
      )}
    </div>
  ));

  const handleClearDate = () => {
    setSelectedDate(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <div
        style={{ background: 'linear-gradient(90deg, #8a2387 0%, #e94057 100%)' }}
        className="p-4 grid gap-4"
      >
        <div className="grid gap-2.5">
          <div className="flex justify-between items-center">
            <Link to="/leads" className="flex items-center text-white">
              <AiOutlineArrowLeft className="text-white text-2xl font-bold mr-2 cursor-pointer" />
              <span>Call Schedules</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="grid gap-2.5">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="d MMM yyyy"
          customInput={<CustomInput ref={ref} />}
          placeholderText="Filter By Date"
        />
      </div>
      <div className="schedule-container">
        <div className="schedule-cards">
          {scheduleData &&
            Object.entries(scheduleData).map(([timeSlot, calls], index) => (
              <div key={index} className="schedule-card">
                <div className="time-slot">{timeSlot}</div>
                {calls.length === 0 ? (
                  <div className="no-call-data">No Calls Scheduled</div>
                ) : (
                  <>
                    {calls.map((call, index1) => (
                      <div key={index1} className="call-entry">
                        <Link to={`/lead/${call.lead_id}`} className="call-link">
                          <div className='flex justify-between items-center mb-2'>
                            <div className="call-name">
                              <FaPhone className="phone-icon" style={{ transform: 'rotate(90deg)' }} />
                              {call.name}
                            </div>
                            <div className="call-time">{call.schedule_time}</div>
                          </div>
                          {call.labels && (
                            <div className="call-labels">{call.labels}</div>
                          )}
                        </Link>
                        {index1 < calls.length - 1 && (
                          <div className="horizontal-line"></div>
                        )}                      
                        </div>
                    ))}
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
});

export default CallSchedules;
