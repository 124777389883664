import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/screens/Login';
import Lead from './components/screens/Lead';
import SingleLead from './components/screens/SingleLead';
import DisposeCall from './components/screens/DisposeCall';
import CallLogs from './components/screens/CallLogs';
import AddLead from './components/screens/AddLead';
import SplashScreen from './components/screens/SplashScreen';
import CallSchedules from './components/screens/CallSchedules';
import { useEffect, useState } from 'react';
import splashImage from './assets/images/hello.jpg';
import 'react-toastify/dist/ReactToastify.css';
import MasterLayout from './views/layout/MasterLayout';

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    const storedShowSplash = localStorage.getItem('showSplash');
    if (storedShowSplash === 'false') {
      setShowSplash(false);
    } else {
      setTimeout(() => {
        localStorage.setItem('showSplash', 'false');
        setShowSplash(false);
      }, 3000);
    }
  }, []);

  return showSplash ? (
    <SplashScreen splashImage={splashImage} />
  ) : (
    <>
      <BrowserRouter>
      <MasterLayout> 
<Routes>
<Route path="/leads" element={<Lead />} />
<Route path="/lead/:id" element={<SingleLead />} />
<Route path="/add-lead" element={<AddLead />} />
<Route path="/end-call" element={<DisposeCall />} />
<Route path="/call-logs" element={<CallLogs />} />
<Route path="/remark/:clientId" element={<DisposeCall/>} />
<Route path="/login" element={<Login />} />
<Route path="/" element={<Login />} />
<Route path="/call-schedules" element={<CallSchedules />} />
</Routes>
      </MasterLayout>
      </BrowserRouter>
    </>
  );
}

export default App;
