// SplashScreen.js
import React from 'react';
import splashImage from '../../assets/images/hello.jpg';

const SplashScreen = () => {
  return (
    <div style={styles.container}>
      <img src={splashImage} alt="Splash Screen" style={styles.image} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#ffffff', // Set the desired background color
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

export default SplashScreen;
