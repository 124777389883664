import React, { useState, useEffect } from 'react';
import { sources, budgets, lookingFor, projects, locations } from '../../../helpers/masterApi';
import { leadUrl } from '../../../config/apiEndpoints';
import { useParams } from 'react-router-dom';
import { getRequest, putRequest } from '../../../store/common';
import { purposes, mediums } from '../../../config/constant';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

const ProfileTab = ({onLeadChange}) => {
    const inputClassName = 'w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-indigo-500 bg-transparent';
    const { id } = useParams();

    if (projects[0].id !== '') {
        projects.unshift({ id: '', project_name: 'Select' });
    }

    if (locations[0].id !== '') {
        locations.unshift({ id: '', loc_name: 'Select' });
    }

    if (budgets[0].id !== '') {
        budgets.unshift({ id: '', name: 'Select' });
    }

    if (lookingFor[0].id !== '') {
        lookingFor.unshift({ id: '', name: 'Select' });
    }

    if (sources[0].id !== '') {
        sources.unshift({ id: '', name: 'Select' });
    }

    const [lead, setLead] = useState({
        client_name: '',
        email: '',
        mobile_no: '',
        alternate_no: '',
        profession_id: '',
        company_name: '',
        lead_loc_id: '',
        project_id: '',
        looking_for_id: '',
        budget_id: '',
        purpose: '',
        medium: '',
        lead_source_id: '',
    });

    const handleNameChange = (e) => {
        setLead({ ...lead, client_name: e.target.value });
    };

    const handleEmailChange = (e) => {
        setLead({ ...lead, email: e.target.value });
    };

    const handleMobileNoChange = (e) => {
        setLead({ ...lead, mobile_no: e.target.value });
    };

    const handleAlternateNoChange = (e) => {
        setLead({ ...lead, alternate_no: e.target.value });
    };

    const handleCompanyNameChange = (e) => {
        setLead({ ...lead, company_name: e.target.value });
    };

    const handleProjectChange = (e) => {
        setLead({ ...lead, project_id: e.target.value });
    };

    const handleLookingForChange = (e) => {
        setLead({ ...lead, looking_for_id: e.target.value });
    };

    const handleBudgetChange = (e) => {
        setLead({ ...lead, budget_id: e.target.value });
    };

    const handlePurposeChange = (e) => {
        setLead({ ...lead, purpose: e.target.value });
    };
    
    const handleSourceChange = (e) => {
        setLead({ ...lead, lead_source_id: e.target.value });
    };

    const handleLocationChange = (e) => {
        setLead({ ...lead, lead_loc_id: e.target.value });
    };

    const handleMediumChange = (e) => {
        setLead({ ...lead, medium: e.target.value });
    };

    const fetchLead = async () => {
        let url = `${leadUrl.lead}/${id}`;
        let temp = await getRequest(url);
        setLead(temp);
        onLeadChange(temp)
    };

    useEffect(() => {
        fetchLead();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEmpty(lead.client_name)) {
            toast.error('Please fill the required fields');
            return;
        }   
        let url = `${leadUrl.lead}/${id}`;
        putRequest(url, {body:lead});
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-white m-4">
                <div className="px-3 py-4">
                    <h2 className="mb-2">Full Name</h2>
                    <input className={inputClassName} type="text" value={lead?.client_name} onChange={handleNameChange} />
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Email</h2>
                    <input className={inputClassName} type="text" value={lead?.email} onChange={handleEmailChange} />
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Mobile Number</h2>
                    <input className={inputClassName} type="text" value={lead?.mobile_no} onChange={handleMobileNoChange} />
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Alternate Number</h2>
                    <input className={inputClassName} type="text" value={lead?.alternate_no} onChange={handleAlternateNoChange} />
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Company Name</h2>
                    <input className={inputClassName} type="text" value={lead?.company_name} onChange={handleCompanyNameChange} />
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Location</h2>
                    <select className={inputClassName} value={lead?.lead_loc_id} onChange={handleLocationChange}>
                        {locations.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.loc_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Project</h2>
                    <select className={inputClassName} value={lead?.project_id} onChange={handleProjectChange}>
                        {projects.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.project_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Looking For</h2>
                    <select className={inputClassName} value={lead?.looking_for_id} onChange={handleLookingForChange}>
                        {lookingFor.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">What is your Budget?</h2>
                    <select className={inputClassName} value={lead?.budget_id} onChange={handleBudgetChange}>
                        {budgets.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Your Purpose</h2>
                    <select className={inputClassName} value={lead?.purpose} onChange={handlePurposeChange}>
                        {purposes.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Medium</h2>
                    <select className={inputClassName} value={lead?.medium} onChange={handleMediumChange}>
                        {mediums.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <h2 className="mb-2">Lead Source</h2>
                    <select className={inputClassName} value={lead?.lead_source_id} onChange={handleSourceChange}>
                        {sources.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="px-3 py-4">
                    <button className="bg-blue-600 text-md font-semibold text-white rounded-lg py-2 px-4 flex justify-between items-center gap-2 h-10" type="submit">
                        <span className="text-base font-normal">Update</span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ProfileTab;
