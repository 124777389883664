import { getSourcesUrl, getLeadTypes, getDispositions, getBudgets, getTelecallers, getProjects, getSubDispositions, getLookingFor, getLocations } from '../config/apiEndpoints';
import { getRequest } from '../store/common';

async function fetchData(url, masterDataKey) {
  let result = localStorage.getItem(masterDataKey);
  if (result) {
    return JSON.parse(result);
  }
  const response = await getRequest(url);
  localStorage.setItem(masterDataKey, JSON.stringify(response));
  return response;
}

export const sources = await fetchData(getSourcesUrl, "sources")
export const leadTypes = await fetchData(getLeadTypes, "leadTypes")
export const dispositions = await fetchData(getDispositions, "dispositions")
export const budgets = await fetchData(getBudgets, "budgets")
export const telecallers = await fetchData(getTelecallers, "telecallers")
export const projects = await fetchData(getProjects, "projects")
export const subDispositions = await fetchData(getSubDispositions, "subDispositions")
export const lookingFor = await fetchData(getLookingFor, "lookingFor")
export const locations = await fetchData(getLocations, "locations")
