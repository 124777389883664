import React, { useState } from 'react';

const Ratings = ({ onRatingChange }) => {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <div className="flex flex-col items-center text-white">
      <p className="mb-2">How was your experience with the client?</p>
      <div className="flex">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`cursor-pointer ${
              star <= rating ? 'text-yellow-400' : 'text-gray-300'
            }`}
            onClick={() => handleRatingChange(star)}
            style={{ fontSize: '30px', marginRight: '5px' }}
          >
            ★
          </span>
        ))}
      </div>
    </div>
  );
};

export default Ratings;
